<template>
  <p-container class="no-bg">
    <template slot="left" v-if="showLeft">
      <a-tree
        :show-line="true"
        :default-expanded-keys="['0-0']"
        :tree-data="treeData"
      ></a-tree>
    </template>
    <template slot="toolbar">
      <!-- <a-button type="primary" @click="handleEdit">编辑</a-button>
      <a-button type="primary">Excel导出</a-button> -->
      <a-input-search placeholder="搜索" enter-button="搜索" size="default" @search="onSearch" />
    </template>

    <a-table
      bordered
      :data-source="dataSource"
      :columns="columns"
      size="middle"
      :row-selection="rowSelection"
      :loading="loading"
    >
      <a
        href="javascript:;"
        @click="analysisShowModal(col)"
        slot="analysisAction"
        slot-scope="text, col"
        >延展分析</a
      >
    </a-table>
    <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
      <div id="demo">
        <div id="graph-chart">
          <div id="main-chart" style="width: 100%; height: 100%"></div>
        </div>
      </div>
    </a-card>
  </p-container>
</template>
<script>
import pic from "./../../../../assets/img/modules/result/test3.png";
import { getmbcg, getmecharts, cgkSelectZtc } from "../../../../api/result";
import * as echarts from 'echarts';
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns = [
  {
    title: "项目名称",
    dataIndex: "zcxmmc",
    width: "10%",
  },
  {
    title: "项目名称(英文)",
    dataIndex: "zcxmmcusa",
  },
  {
    title: "电力科技成果分类名称",
    dataIndex: "cmflmc",
  },
  {
    title: "电力科技成果分类代码",
    dataIndex: "cmfldm",
  },
  {
    title: "",
    dataIndex: "analysis",
    scopedSlots: { customRender: "analysisAction" },
  },
];
const dataSource = [
  {
    key: "0",
    name: "项目1",
    englishName: "english",
    classification: "名称1",
    classificationCode: "001",
    analysis: "延展分析",
  },
];
export default {
  name: "QuickSearch",
  data() {
    return {
      loading: false,
      treeData: [],
      rowSelection,
      dataSource,
      columns,
      updateVisible: false,

      dataecharts: [],
      echartsdata: [],
      echartslinks: [],
      chartsData: []
    };
  },
  computed: {
    type() {
      return this.$route.name.substr(this.$route.name.lastIndexOf("_") + 1);
    },
    // showLeft(){
    //   return this.type!='all'
    // }
  },
  mounted() {
     this.initChart();
    getmbcg()
      .then((res) => {
        this.dataSource = res.data;
        console.log(res.data);
      })
      .catch((e) => {})
      .catch((e) => {});
  },
  watch: {
    $route(to, from) {
      this.initPage();
    },
  },
  created() {
    this.initPage();
  },
  methods: {
    onSearch(val) {
      cgkSelectZtc({name: val})
        .then(res => {
          this.dataSource = res.data
        })
    },
    initPage() {
      //tree
      switch (this.type) {
        case "subject":
          this.treeData = subjectTreeData;
          break;
        case "device":
          this.treeData = deviceTreeData;
          break;
        default:
          break;
      }
      //clear searchbar

      //loadList
    },
    analysisShowModal(col) {
      this.analysisVisible = true;
      this.mdl = { ...col };
      this.dataecharts = [];
      this.echartslinks = [];
      getmecharts({
        cmflmc: col.cmflmc,
      })
        .then((res) => {
          // TODO:
          const { data: originData } = res.data
          originData.forEach(oneCharts => {
            let chartsDataArr = [],
                echartslinksArr = []
            oneCharts.data.forEach(item => {
              var obj = {};
              var obj2 = {};
              obj.name = item.name;
              obj.category = parseInt(item.category);
              obj2.source = item.source;
              obj2.target = item.target;
              chartsDataArr.push(obj)
              echartslinksArr.push(obj2)
            })
            this.chartsData.push({
              chartsDataArr,
              echartslinksArr
            })
          })
          this.initChart();
          // console.log(res.data.data);
        })
        .catch((e) => {})
        .catch((e) => {});
    },
    handleOk(e) {
      console.log(e);
      this.updateVisible = false;
    },
    initChart: function() {
      let myChart = echarts.init(document.getElementById("main-chart"));
      myChart.resize(); //自适应大小
      myChart.clear();
      myChart.setOption(this.setOption());
    },
    setOption: function() {

      const series = this.chartsData.map(item => {
        return {
            type: "graph",
            layout: "force",
            symbolSize: 50, //倘若该属性不在link里，则其表示节点的大小；否则即为线两端标记的大小
            symbolSize: (value, params) => {
              switch (params.data.category) {
                case 0:
                  return 100;
                  break;
                case 1:
                  return 50;
                  break;
              }
            },
            roam: true, //鼠标缩放功能
            label: {
              show: true, //是否显示标签
            },
            focusNodeAdjacency: true, //鼠标移到节点上时突出显示结点以及邻节点和边
            edgeSymbol: ["none", "arrow"], //关系两边的展现形式，也即图中线两端的展现形式。arrow为箭头
            edgeSymbolSize: [4, 10],
            draggable: true,
            edgeLabel: {
              fontSize: 10, //关系（也即线）上的标签字体大小
            },
            force: {
              repulsion: 2000,
              edgeLength: 120,
            },
            data: item.chartsDataArr,
            // links: [],
            links: item.echartslinksArr,
            lineStyle: {
              opacity: 0.9,
              width: 2,
              curveness: 0,
            },
          }
      })
      
      let option = {
        title: {},
        tooltip: {}, //提示框
        animationDurationUpdate:1000,
        animationEasingUpdate: "quinticInOut",
        series
      };
      return option;
    },
  },
};
</script>

<style scoped>
#graph-chart {
  height: 800px;
  width: 100%;
}
.ant-input-search {
  width: 40%;
}
</style>
